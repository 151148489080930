import { createSlice } from '@reduxjs/toolkit';
import { LabelState } from '../../@types/label';

const initialState: LabelState = {
  isLoading: false,
  labelList: {},
  labelListParams: {},
};

const slice = createSlice({
  name: 'label',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getLabelListSuccess(state, action) {
      state.isLoading = false;
      state.labelList = action.payload;
    },

    setLabelListParams(state, action) {
      state.labelListParams = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getLabelListSuccess,
  setLabelListParams,
} = slice.actions;
