import { createSlice } from '@reduxjs/toolkit';
import { RecipeState } from '../../@types/recipe';

const initialState: RecipeState = {
  isLoading: false,
  recipeList: {},
  recipeListParams: {},
};

const slice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getRecipeListSuccess(state, action) {
      state.isLoading = false;
      state.recipeList = action.payload;
    },

    setRecipeListParams(state, action){
      state.recipeListParams = action.payload;
    },

    getRecipeSuccess(state, action) {
      state.isLoading = false;
      state.recipe = action.payload;
    },

    editRecipeSuccess(state, action) {
      state.recipeList = {
        meta: state.recipeList.meta,
        data: state.recipeList.data?.map(obj => obj.id === action.payload.id ? action.payload : obj)
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getRecipeListSuccess, setRecipeListParams,
  getRecipeSuccess, editRecipeSuccess
} = slice.actions;

