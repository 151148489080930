function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  change: path(ROOTS_DASHBOARD, '/change'),
  arrangement: path(ROOTS_DASHBOARD, '/arrangement'),
  remainder: path(ROOTS_DASHBOARD, '/remainder'),
  cancellation: path(ROOTS_DASHBOARD, '/cancellation'),
  replacement: path(ROOTS_DASHBOARD, '/replacement'),
  changes: path(ROOTS_DASHBOARD, '/changes'),
  tasks: path(ROOTS_DASHBOARD, '/tasks'),
  account: path(ROOTS_DASHBOARD, '/account'),
  employees: path(ROOTS_DASHBOARD, '/employees'),
  labels: path(ROOTS_DASHBOARD, '/labels'),
  reasons: path(ROOTS_DASHBOARD, '/reasons'),
  terminal: path(ROOTS_DASHBOARD, '/terminal'),
  about: path(ROOTS_DASHBOARD, '/about'),
  recipe: {
    root: path(ROOTS_DASHBOARD, '/recipe'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/recipe/${id}/edit`),
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    new: path(ROOTS_DASHBOARD, '/users/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/users/${id}/edit`),
  },
  imports: {
    root: path(ROOTS_DASHBOARD, '/imports'),
    messages: (id: number) => path(ROOTS_DASHBOARD, `/imports/${id}/messages`),
  },
};
