import { createSlice } from '@reduxjs/toolkit';

import { RemainState } from '../../@types/remain';

const initialState: RemainState = {
  isLoading: false,
  remainList: {},
  remainListParams: {},
};

const slice = createSlice({
  name: 'remain',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getRemainListSuccess(state, action) {
      state.isLoading = false;
      state.remainList = action.payload;
    },

    setRemainListParams(state, action) {
      state.remainListParams = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getRemainListSuccess,
  setRemainListParams,
} = slice.actions;
