import { createSlice } from '@reduxjs/toolkit';

import { NotificationState } from '../../@types/notification';

const initialState: NotificationState = {
  notificationList: [],
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getNotificationListSuccess(state, action) {
      state.notificationList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getNotificationListSuccess,
} = slice.actions;
