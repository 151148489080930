import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import taskReducer from './slices/task';
import workplaceReducer from './slices/workplace';
import workshopReducer from './slices/workshop';
import remainReducer from './slices/remain';
import shiftReducer from './slices/shift';
import notificationReducer from './slices/notification';
import dictReducer from './slices/dict';
import arrangementReducer from './slices/arrangement';
import reasonReducer from './slices/reason';
import productReducer from './slices/product';
import replacementReducer from './slices/replacement';
import importReducer from './slices/import';
import changeReducer from './slices/change';
import labelReducer from './slices/label';
import recipeReducer from './slices/recipe';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  task: taskReducer,
  workplace: workplaceReducer,
  workshop: workshopReducer,
  remain: remainReducer,
  shift: shiftReducer,
  notification: notificationReducer,
  dict: dictReducer,
  arrangement: arrangementReducer,
  reason: reasonReducer,
  product: productReducer,
  replacement: replacementReducer,
  import: importReducer,
  change: changeReducer,
  label: labelReducer,
  recipe: recipeReducer,
});

export { rootPersistConfig, rootReducer };
