import { createSlice } from '@reduxjs/toolkit';

import { DictState } from '../../@types/dict';

const initialState: DictState = {
  isLoading: false,
  dict: [],
};

const slice = createSlice({
  name: 'dict',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getDictListSuccess(state, action) {
      state.isLoading = false;
      const data = action.payload;
      const found = state.dict.find(dl => dl.type === data.type);
      if(found) {
        state.dict.map(dl => ({
          type: dl.type,
          list: dl.type === data.type ? data.list : dl.list,
        }))
      }
      else {
        const newDict = [...state.dict];
        newDict.push(data);
        state.dict = newDict;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getDictListSuccess,
} = slice.actions;
