import { createSlice } from '@reduxjs/toolkit';
import { ArrangementState, ArrangementType } from '../../@types/arrangement';

const initialState: ArrangementState = {
  isLoading: false,
  changed: false,
  arrangementList: [],
};

const slice = createSlice({
  name: 'arrangement',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getArrangementListSuccess(state, action) {
      state.arrangementList = action.payload;
      state.isLoading = false;
      state.changed = false;
    },

    addArrangement(state, action) {
      state.arrangementList = [...state.arrangementList, action.payload];
      state.changed = true;
    },

    setMainArrangement(state, action) {
      const arrangement = action.payload;
      state.arrangementList = state.arrangementList.map(a => (arrangement.workplace_id === a.workplace_id && arrangement.shift_id === a.shift_id) ? {
        ...a,
        type: arrangement.user_id === a.user_id ? ArrangementType.MAIN : ArrangementType.ORDINARY,
      } : a);
      state.changed = true;
    },

    removeArrangement(state, action) {
      const arrangement = action.payload;
      state.arrangementList = state.arrangementList.filter(a => (arrangement.workplace_id !== a.workplace_id || arrangement.shift_id !== a.shift_id || arrangement.user_id !== a.user_id));
      state.changed = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getArrangementListSuccess, addArrangement, setMainArrangement, removeArrangement
} = slice.actions;
