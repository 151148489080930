import { CommonState, ListParams, Pagination, Rect } from './common';
import { User } from './user';

export enum WorkplaceType {
  PRODUCTION = 'production',
  PICKING = 'picking',
  RATION = 'ration',
  STORAGE = 'storage',
}

export type WorkplaceGraph = {
  container?: Rect;
  title?: Rect;
  users?: Rect;
};

export type UserWorkplace = {
  id: number;
  title: string;
  type: WorkplaceType;
};

export type Workplace = {
  id: number;
  title: string;
  workshop_title: string;
  graph?: WorkplaceGraph;
  user_count_min: number;
  user_count_max: number;
  users: User[];
  type: WorkplaceType;
};

export type WorkplaceList = {
  data?: Workplace[];
  meta?: Pagination;
};

export type WorkplaceListParams = ListParams;

export type WorkplaceState = CommonState & {
  workplaceList: WorkplaceList;
  workplaceListParams: WorkplaceListParams;
};
