// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  invoice: getIcon('ic_cart'),
  items: getIcon('ic_menu_item'),
  video: getIcon('ic_kanban'),
};

const navConfig = [
  {
    subheader: 'Общее',
    items: [
      {
        title: 'Пересменка',
        path: PATH_DASHBOARD.change,
        icon: ICONS.dashboard,
        roles: ['view', 'workers'],
      },
      {
        title: 'Расстановка',
        path: PATH_DASHBOARD.arrangement,
        icon: ICONS.dashboard,
        roles: ['view', 'workers'],
      },
      {
        title: 'Остаток',
        path: PATH_DASHBOARD.remainder,
        icon: ICONS.dashboard,
        roles: ['view', 'remainders'],
      },
      {
        title: 'Отмены',
        path: PATH_DASHBOARD.cancellation,
        icon: ICONS.dashboard,
        roles: ['view', 'reasons'],
      },
      {
        title: 'Замены ингридиентов',
        path: PATH_DASHBOARD.replacement,
        icon: ICONS.dashboard,
        roles: ['edit', 'replacements'],
      },
      {
        title: 'Корректировки',
        path: PATH_DASHBOARD.changes,
        icon: ICONS.dashboard,
        roles: ['view', 'reports'],
      },
      {
        title: 'Список задач',
        path: PATH_DASHBOARD.tasks,
        icon: ICONS.dashboard,
        roles: ['view', 'tasks'],
      },
      {
        title: 'Причины отмен',
        path: PATH_DASHBOARD.reasons,
        icon: ICONS.dashboard,
        roles: ['view', 'reasons'],
      },
      {
        title: 'Транспортные ярлыки',
        path: PATH_DASHBOARD.labels,
        icon: ICONS.dashboard,
        roles: ['view', 'labels'],
      },
    ],
  },
  {
    subheader: 'Справочники',
    items: [
      {
        title: 'Рецепты',
        path: PATH_DASHBOARD.recipe.root,
        icon: ICONS.items,
        roles: ['edit', 'recipes'],
      },
    ],
  },
  {
    subheader: 'Администрирование',
    items: [
      {
        title: 'Пользователи',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
        roles: ['edit', 'users'],
      },
      {
        title: 'Допустимые сотрудники',
        path: PATH_DASHBOARD.employees,
        icon: ICONS.user,
        roles: ['edit', 'users'],
      },
      {
        title: 'Синхронизация',
        path: PATH_DASHBOARD.imports.root,
        icon: ICONS.items,
        roles: ['edit', 'users'],
      },
    ],
  },
  {
    subheader: 'Аккаунт',
    items: [
      {
        title: 'Профиль',
        path: PATH_DASHBOARD.account,
        icon: ICONS.dashboard,
        roles: [],
      },
      {
        title: 'Выйти',
        path: PATH_AUTH.login,
        icon: ICONS.dashboard,
        roles: []
      },
    ],
  },

];

export default navConfig;
