import { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { List, Collapse, Link } from '@mui/material';
import { NavListProps } from '../type';
import NavItem from './NavItem';
import { getActive, isExternalLink } from '..';
import useAuth from '../../../hooks/useAuth';
import { Notification } from '../../../@types/notification';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChildren: boolean;
  notificationList?: Notification[];
  isCollapse?: boolean;
};

export default function NavList({
  data,
  depth,
  hasChildren,
  notificationList,
  isCollapse = false,
}: NavListRootProps) {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { logout } = useAuth();
  const active = getActive(data.path, pathname);

  const [open, setOpen] = useState(active);

  const handleClickItem = () => {
    if (!hasChildren) {
      navigate(data.path);
    }
    if (data.title === 'Выйти') {
      logout();
    }
    setOpen(!open);
  };

  const notificationCount = useMemo(() => {
    return notificationList?.reduce(
      (prev: number, curr: Notification) =>
        prev + +Boolean(curr.type === 'inner' && '/dashboard/' + curr.part === data.path),
      0
    );
  }, [notificationList]);

  return (
    <>
      {isExternalLink(data.path) ? (
        <Link href={data.path} target="_blank" rel="noopener" underline="none">
          <NavItem
            item={data}
            depth={depth}
            open={open}
            active={active}
            isCollapse={isCollapse}
            notificationCount={notificationCount}
          />
        </Link>
      ) : (
        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isCollapse={isCollapse}
          onClick={handleClickItem}
          notificationCount={notificationCount}
        />
      )}

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            <NavSubList data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
};

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChildren={!!list.children}
        />
      ))}
    </>
  );
}
