import { createSlice } from '@reduxjs/toolkit';
import { WorkplaceState } from '../../@types/workplace';

const initialState: WorkplaceState = {
  isLoading: false,
  workplaceList: {},
  workplaceListParams: {},
};

const slice = createSlice({
  name: 'workplace',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getWorkplaceListSuccess(state, action) {
      state.isLoading = false;
      state.workplaceList = action.payload;
    },

    setWorkplaceListParams(state, action) {
      state.workplaceListParams = action.payload;
    },

    editWorkplaceSuccess(state, action) {
      state.workplaceList = {
        meta: state.workplaceList.meta,
        data: state.workplaceList.data?.map((obj) =>
          obj.id === action.payload.id ? action.payload : obj
        ),
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getWorkplaceListSuccess,
  setWorkplaceListParams,
  editWorkplaceSuccess,
} = slice.actions;
