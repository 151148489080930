import { createSlice } from '@reduxjs/toolkit';
import { ImportState } from '../../@types/import';

const initialState: ImportState = {
  isLoading: false,
  importList: [],
  importListParams: {},
  messageList: [],
};

const slice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getImportListSuccess(state, action) {
      state.isLoading = false;
      state.importList = action.payload;
    },

    setImportListParams(state, action) {
      state.importListParams = action.payload;
    },

    getImportMessageListSuccess(state, action) {
      state.isLoading = false;
      state.messageList = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getImportListSuccess,
  setImportListParams,
  getImportMessageListSuccess,
} = slice.actions;
