import axios from 'axios';
import { dispatch } from '../redux/store';
import { getNotificationListSuccess } from '../redux/slices/notification';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstanceBase = axios.create({
  baseURL: HOST_API,
});

//middleware
axiosInstanceBase.interceptors.response.use(
  (response) => {
    if(response.data?.notifications) {
      dispatch(getNotificationListSuccess(response.data?.notifications));
    }
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

const ApiClients = {
  axiosBase: axiosInstanceBase,
}

export default ApiClients;
