import { createSlice } from '@reduxjs/toolkit';

import { TaskState } from '../../@types/task';

const initialState: TaskState = {
  isLoading: false,
  taskList: {},
  taskListParams: {},
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getTaskListSuccess(state, action) {
      state.isLoading = false;
      state.taskList = action.payload;
    },

    appendTaskListSuccess(state, action) {
      state.isLoading = false;
      state.taskList = (state.taskListParams.prev_page && state.taskList.data) ?
        (state.taskListParams.prev_page < action.payload.meta.current_page) ?
          {
            data: [...state.taskList.data.slice(-action.payload.meta.per_page), ...action.payload.data],
            meta: action.payload.meta
          } : {
            data: [...action.payload.data, ...state.taskList.data.slice(0, action.payload.meta.per_page)],
            meta: action.payload.meta
          } : action.payload;
    },

    getTaskComponentListSuccess(state, action) {
      state.taskComponentList = action.payload
    },

    setTaskListParams(state, action) {
      state.taskListParams = action.payload;
    },

    updateTaskSuccess(state, action) {
      state.taskList = {
        meta: state.taskList.meta,
        data: state.taskList.data?.map((obj) =>
          obj.id === action.payload.id ? action.payload : obj
        ),
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getTaskListSuccess,
  appendTaskListSuccess,
  getTaskComponentListSuccess,
  setTaskListParams,
  updateTaskSuccess,
} = slice.actions;
