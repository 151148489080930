import { createSlice } from '@reduxjs/toolkit';

import { ReplacementState } from '../../@types/replacement';

const initialState: ReplacementState = {
  isLoading: false,
  replacementList: {},
  replacementListParams: {},
};

const slice = createSlice({
  name: 'replacement',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getReplacementListSuccess(state, action) {
      state.isLoading = false;
      state.replacementList = action.payload;
    },

    setReplacementListParams(state, action) {
      state.replacementListParams = action.payload;
    },

    addReplacementSuccess(state, action) {
      state.replacementList = {
        meta: state.replacementList.meta,
        data: [action.payload, ...(state.replacementList.data || [])],
      };
    },

    editReplacementSuccess(state, action) {
      state.replacementList = {
        meta: state.replacementList.meta,
        data: state.replacementList.data?.map((obj) =>
          obj.id === action.payload.id ? action.payload : obj
        ),
      };
    },

    deleteReplacementSuccess(state, action) {
      state.replacementList = {
        meta: state.replacementList.meta,
        data: state.replacementList.data?.filter((obj) => obj.id !== action.payload.id),
      };
    },

    getReplacementVariantListSuccess(state, action) {
      state.isLoading = false;
      state.replacementVariantList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getReplacementListSuccess,
  setReplacementListParams,
  addReplacementSuccess,
  editReplacementSuccess,
  deleteReplacementSuccess,
  getReplacementVariantListSuccess
} = slice.actions;
