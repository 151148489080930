import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import PermissionGuard from '../guards/PermissionGuard';
import useAuth from '../hooks/useAuth';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// layouts
import DashboardLayout from '../layouts/dashboard';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import useAbility from '../hooks/useAbility';
import { WorkplaceType } from '../@types/workplace';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const ability = useAbility();
  const { isAuthenticated, user } = useAuth();
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/" replace />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element:
            (isAuthenticated && user && user.permissions) ? (
              ability('view', 'terminal') ? (
                <Navigate to="/dashboard/terminal" replace />
              ) : ability('edit', 'shifts') ? (
                <Navigate to="/dashboard/change" replace />
              ) : ability('view', 'workers') ? (
                <Navigate to="/dashboard/arrangement" replace />
              ) : ability('view', 'tasks') ? (
                <Navigate to="/dashboard/tasks" replace />
              ) : ability('print', 'labels') ? (
                <Navigate to="/dashboard/editor" replace />
              ) : ability('view', 'remainders') ? (
                <Navigate to="/dashboard/remainder" replace />
              ) : ability('view', 'reasons') ? (
                <Navigate to="/dashboard/cancellation" replace />
              ) : ability('edit', 'replacements') ? (
                <Navigate to="/dashboard/replacement" replace />
              ) : ability('view', 'reports') ? (
                <Navigate to="/dashboard/changes" replace />
              ) : ability('edit', 'users') ? (
                <Navigate to="/dashboard/users" replace />
              ) : (
                <Navigate to="/dashboard/account" replace />
            )) : (
              <LoadingScreen />
            ),
          index: true,
        },
        {
          path: 'change',
          element: (
            <PermissionGuard action="edit" model="shifts">
              <PageChange />
            </PermissionGuard>
          ),
        },
        {
          path: 'arrangement',
          element: (
            <PermissionGuard action="view" model="workers">
              <PageArrangement />
            </PermissionGuard>
          ),
        },
        {
          path: 'remainder',
          element: (
            <PermissionGuard action="view" model="remainders">
              <PageRemainder />
            </PermissionGuard>
          ),
        },
        {
          path: 'cancellation',
          element: (
            <PermissionGuard action="view" model="reasons">
              <PageCancellation />
            </PermissionGuard>
          ),
        },
        {
          path: 'replacement',
          element: (
            <PermissionGuard action="edit" model="replacements">
              <PageReplacement />
            </PermissionGuard>
          ),
        },
        {
          path: 'changes',
          element: (
            <PermissionGuard action="view" model="reports">
              <PageChanges />
            </PermissionGuard>
          ),
        },
        {
          path: 'employees',
          element: (
            <PermissionGuard action="edit" model="users">
              <PageEmployees />
            </PermissionGuard>
          ),
        },
        {
          path: 'tasks',
          element: (
            <PermissionGuard action="view" model="tasks">
              <PageTasks />
            </PermissionGuard>
          ),
        },
        {
          path: 'reasons',
          element: (
            <PermissionGuard action="view" model="reasons">
              <PageReasons />
            </PermissionGuard>
          ),
        },
        {
          path: 'labels',
          element: (
            <PermissionGuard action="view" model="labels">
              <PageLabels />
            </PermissionGuard>
          ),
        },
        {
          path: 'terminal',
          element: (
            <PermissionGuard action="view" model="terminal">
              {user?.workplace ? (user.workplace.type === WorkplaceType.STORAGE ? <PageStorageTerminal /> : <PageTaskList />) : <PageEmptyTerminal />}
            </PermissionGuard>
          ),
        },
        {
          path: 'about',
          element: (
            <PermissionGuard action="view" model="terminal">
              <PageAbout />
            </PermissionGuard>
          ),
        },
        { path: 'account', element: <UserAccount /> },
        {
          path: 'users',
          children: [
            {
              element: (
                <PermissionGuard action="edit" model="users">
                  <UserList />
                </PermissionGuard>
              ),
              index: true,
            },
            {
              path: 'new',
              element: (
                <PermissionGuard action="edit" model="users">
                  <UserCreate />
                </PermissionGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <PermissionGuard action="edit" model="users">
                  <UserCreate />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: 'imports',
          children: [
            {
              element: (
                <PermissionGuard action="edit" model="users">
                  <ImportList />
                </PermissionGuard>
              ),
              index: true,
            },
            {
              path: ':id/messages',
              element: (
                <PermissionGuard action="edit" model="users">
                  <ImportMessageList />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: 'recipe',
          children: [
            {
              element: (
                <PermissionGuard action="edit" model="recipes">
                  <RecipeList />
                </PermissionGuard>
              ),
              index: true,
            },
            {
              path: ':id/edit',
              element: (
                <PermissionGuard action="edit" model="recipes">
                  <RecipeCreate />
                </PermissionGuard>
              ),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '403', element: <NoAccess /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageChanges = Loadable(lazy(() => import('../pages/PageChanges')));
const PageTasks = Loadable(lazy(() => import('../pages/PageTasks')));
const PageArrangement = Loadable(lazy(() => import('../pages/PageArrangement')));
const PageCancellation = Loadable(lazy(() => import('../pages/PageCancellation')));
const PageChange = Loadable(lazy(() => import('../pages/PageChange')));
const PageRemainder = Loadable(lazy(() => import('../pages/PageRemains')));
const PageReplacement = Loadable(lazy(() => import('../pages/PageReplacement')));
const PageEmployees = Loadable(lazy(() => import('../pages/PageEmployees')));
const PageReasons = Loadable(lazy(() => import('../pages/PageReasons')));
const PageLabels = Loadable(lazy(() => import('../pages/PageLabels')));
const PageTaskList = Loadable(lazy(() => import('../pages/PageTaskList')));
const PageStorageTerminal = Loadable(lazy(() => import('../pages/PageStorageTerminal')));
const PageEmptyTerminal = Loadable(lazy(() => import('../pages/PageEmptyTerminal')));
const PageAbout = Loadable(lazy(() => import('../pages/PageAbout')));
const UserList = Loadable(lazy(() => import('../pages/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/user/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/user/UserCreate')));
const ImportList = Loadable(lazy(() => import('../pages/import/ImportList')));
const ImportMessageList = Loadable(lazy(() => import('../pages/import/MessageList')));
const RecipeList = Loadable(lazy(() => import('../pages/recipe/RecipeList')));
const RecipeCreate = Loadable(lazy(() => import('../pages/recipe/RecipeCreate')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const NoAccess = Loadable(lazy(() => import('../pages/Page403')));
