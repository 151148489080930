import { CommonState } from './common';

export enum ArrangementType {
  MAIN = 'main',
  ORDINARY = 'ordinary',
}

export enum ArrangementStatus {
  NEW = 'new',
  REPEAT = 'repeat',
}

export type Arrangement = {
  workplace_id: number;
  shift_id: number;
  user_id: number;
  type: ArrangementType;
  status: ArrangementStatus;
}

export type ArrangementState = CommonState & {
  changed: boolean;
  arrangementList: Arrangement[];
};