import { createSlice } from '@reduxjs/toolkit';

import { ChangeState } from '../../@types/change';

const initialState: ChangeState = {
  isLoading: false,
  changeList: {},
  changeListParams: {},
};

const slice = createSlice({
  name: 'change',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getChangeListSuccess(state, action) {
      state.isLoading = false;
      state.changeList = action.payload;
    },

    setChangeListParams(state, action) {
      state.changeListParams = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getChangeListSuccess,
  setChangeListParams,
} = slice.actions;
