// @mui
import { List, Box } from '@mui/material';
//
import { NavSectionProps } from '../type';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import { Can } from '../../can';
import { Fragment } from 'react';
import useAbility from '../../../hooks/useAbility';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const ability = useAbility();

  const { notificationList } = useSelector((state: RootState) => state.notification);

  return (
    <Box {...other}>
      {navConfig.map((group) => {
          const hasPermission = group.items.some((list) => {
            if (list.roles.length > 0) {
              return ability(list.roles[0], list.roles[1]);
            }
            return true;
          });

          return (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              {hasPermission && (
                <ListSubheaderStyle
                  sx={{
                    ...(isCollapse && {
                      opacity: 0,
                    }),
                  }}
                >
                  {group.subheader}
                </ListSubheaderStyle>
              )}
              {group.items.map((list) => (
                <Fragment key={list.title + list.path}>
                  {list.roles.length > 0 ? (
                    <Can do={list.roles[0]} on={list.roles[1]}>
                      <NavList
                        data={list}
                        depth={1}
                        hasChildren={!!list.children}
                        isCollapse={isCollapse}
                        notificationList={notificationList}
                      />
                    </Can>
                  ) : (
                    <NavList
                      data={list}
                      depth={1}
                      hasChildren={!!list.children}
                      isCollapse={isCollapse}
                      notificationList={notificationList}
                    />
                  )}
                </Fragment>
              ))}
            </List>
          );
        })}
    </Box>
  );
}
