import { AnimatePresence, m } from 'framer-motion';
import { useState, useEffect } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Stack, Divider, Typography } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import cssStyles from '../../../utils/cssStyles';
import { NAVBAR } from '../../../config';

import { varFade } from '../../animate';

import { useLocation, useNavigate } from 'react-router';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  width: NAVBAR.BASE_WIDTH,
  flexDirection: 'column',
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

// ----------------------------------------------------------------------

const menuList = [
  { action: 'open', path: PATH_DASHBOARD.about, title: 'О терминале' },
  { action: 'open', path: PATH_DASHBOARD.terminal, title: 'Главное окно' },
  { action: 'logout', title: 'Выйти' },
];

export default function SettingsDrawer() {
  const { themeDirection } = useSettings();
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [isOpen, setOpen] = useState(false);
  const [active, setActive] = useState(-1);

  useEffect(() => {
    setActive(menuList.findIndex(item => item.path === location.pathname) || -1);
  }, [location?.pathname]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const handlers: { [key: string]: () => void } = {
        F1: () => {
          setOpen(!isOpen);
        },
        ArrowUp: () => {
          if (isOpen) {
            setActive((index: number) => Math.max(0, index - 1));
          }
        },
        ArrowDown: () => {
          if (isOpen) {
            setActive((index: number) => Math.min(menuList.length - 1, index + 1));
          }
        },
        Enter: () => {
          if (isOpen) {
            handleMenuItemClick(active);
          }
        }
      };

      const handler = handlers[event.key];
      if (handler) {
        handler();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, active, navigate]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
  }, [isOpen]);

  const varSidebar = varFade({
    distance: NAVBAR.BASE_WIDTH,
    durationIn: 0.32,
    durationOut: 0.32,
  })[themeDirection !== 'rtl' ? 'inRight' : 'inLeft'];

  const handleMenuItemClick = (index: number) => {
    setOpen(false);
    const item = menuList[index];
    if(item.action === 'open' ) {
      navigate(item.path || '');
    }
    else if(item.action === 'logout') {
      logout();
      navigate(PATH_AUTH.login);
    }
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <RootStyle {...varSidebar}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                Навигация
              </Typography>
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack>
              {menuList.map((item, i) => (
                <Stack
                  key={i}
                  sx={{
                    p: 3,
                    background: active === i ? '#E1E1E1' : '#fff',
                    '&:hover': {
                      background: '#E1E1E1',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleMenuItemClick(i)}
                >
                  <Typography variant="subtitle2">{item.title}</Typography>
                </Stack>
              ))}
            </Stack>
          </RootStyle>
        )}
      </AnimatePresence>
    </>
  );
}
