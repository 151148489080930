import { createSlice } from '@reduxjs/toolkit';
import { WorkshopState } from '../../@types/workshop';

const initialState: WorkshopState = {
  isLoading: false,
  workshopList: [],
};

const slice = createSlice({
  name: 'workshop',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getWorkshopListSuccess(state, action) {
      state.isLoading = false;
      state.workshopList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getWorkshopListSuccess,
} = slice.actions;
