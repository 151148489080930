import { createSlice } from '@reduxjs/toolkit';
import { ShiftState } from '../../@types/shift';

const initialState: ShiftState = {
  isLoading: false,
  shiftList: {},
  shiftListParams: {},
};

const slice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getShiftListSuccess(state, action) {
      state.isLoading = false;
      state.shiftList = action.payload;
    },

    setShiftListParams(state, action) {
      state.shiftListParams = action.payload;
    },

    getShiftSuccess(state, action) {
      state.isLoading = false;
      state.shift = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getShiftListSuccess,
  setShiftListParams,
  getShiftSuccess,
} = slice.actions;
