import useAuth from './useAuth';

export default () => {
  const { user, isAuthenticated } = useAuth();

  return (action: string, model: string) => {
    if (isAuthenticated && user && user.permissions) {
      return user.permissions.some((permission) => {
        const parts = permission.name.split(' ');
        return parts[0] === action && parts[1] === model;
      });
    }

    return false;
  };
};
